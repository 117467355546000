import * as React from 'react';

import MainLayout from 'components/Layout/MainLayout';
import MySEO from 'shared/MySEO';
import FixedTopBarScholarshipScheduleNOffline from '../shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import { HeaderLandingLuyenThi } from '../components/LandingLuyenThi/components/HeaderLandingLuyenThi';
import { LandingLuyenThiBannerLayout } from '../components/LandingLuyenThi/sections/LandingLuyenThiIELTSBanner';
import { withMarketingSection } from '../components/withMarketingSection';
import { BannerLuyenThi } from '../components/LandingLuyenThi/components/BannerLuyenThi';
import styled from 'styled-components';
import { BenefitList } from '../components/LandingLuyenThi/components/BenefitList';
import { useCoursesWithTeacher } from '../shared/CourseLanding/Courses';
import { CourseList } from '../components/LandingLuyenThi/components/CourseList';
import { WhyLearnAtDOLSection } from '../components/LandingLuyenThi/components/WhyLearnAtDOL';
import { fromScreen } from '../utils/media-query/responsive.util';
import { LandingSectionLayout } from '../components/LandingLuyenThi/sections/LandingSectionLayout';
import { LandingSectionTitle } from '../shared/Layout/SectionTitle';

const BannerLayout = withMarketingSection(
  styled.div`
    margin-top: 40px;

    display: grid;
    grid-row-gap: 40px;

    ${fromScreen(768)} {
      grid-row-gap: 80px;
      margin-top: 80px;
    }
  `,
  {
    style: {
      padding: 0,
      position: 'relative',
      zIndex: 1
    },
    selfish: true
  },
  { maxWidth: 1080 }
);

const mobileGroupCourses = [
  {
    name: '🚀 IELTS cấp tốc',
    levels: ['SINT', 'INT0', 'INT1', 'INT2']
  },
  {
    name: '📚 IELTS cơ bản',
    levels: ['English 101', 'PRE', '4.0', '5.0']
  },
  {
    name: '🏅 IELTS nâng cao',
    levels: ['6.0', '6.5', '7.0', 'WS8']
  }
];
const desktopGroupCourses = [
  {
    name: 'Khóa IELTS cấp tốc',
    levels: ['SINT', 'INT0', 'INT1', 'INT2']
  },
  {
    name: 'Khóa IELTS cơ bản',
    levels: ['English 101', 'PRE', '4.0', '5.0']
  },
  {
    name: 'Khóa IELTS nâng cao',
    levels: ['6.0', '6.5', '7.0', 'WS8']
  }
];
const bannerData = {
  titles: ['Luyện thi IELTS', 'Tư Duy - Cấp Tốc', 'Đảm Bảo Đầu Ra'],
  description: [
    'Phương pháp Linearthinking, giáo viên tận tâm,',
    'nền tảng công nghệ độc quyền!'
  ],
  imgHandle: 'iwQ7VaSpGqNW4UHSaniQ'
};
const benefitList = [
  {
    icon: 'yL5V3nQrSfihakEOWesH',
    text: 'Linearthinking'
  },
  {
    icon: 'JHpFBVM8RmSCXLA1hhjY',
    text: 'Tăng điểm siêu tốc'
  },
  {
    icon: 'hkcCAGjRTzSZeJ5oJry4',
    text: 'Học được, xài được'
  },
  {
    icon: 'PFnY1NKbTCmrXSA8Pekt',
    text: 'Giáo viên giỏi và có tâm'
  },
  {
    icon: 'YfyxuQVcSk2DOlDMqNRG',
    text: 'Ứng dụng AI trong việc học'
  },
  {
    icon: 'YBXWslUmQxKXoJRAca90',
    text: 'Cơ sở vật chất hiện đại'
  }
];
const reasonsLearnAtDOL = [
  {
    image: 'xegQU3S0S1q8MAtrk7i6',
    title: 'Phương pháp Linearthinking được cục SHTT cấp chứng nhận',
    description:
      'Phương pháp Linearthinking được tạo ra từ tư duy toán học, kỹ thuật siêu trí nhớ và nghiên cứu ảnh hưởng của việc học Tiếng Việt lên Tiếng Anh để cho ra đời phương pháp học Tiếng Anh tư duy, dễ dàng, hiệu quả nhanh chóng, học được ứng dụng được cho người Việt.',
    action: {
      type: 'VIDEO',
      link: {
        title: 'Xem video bài giảng Linear',
        modalTitle: 'Video bài giảng về Linear',
        url: 'https://www.youtube.com/embed/mlYspfvRvlE'
      }
    }
  },
  {
    image: 'Wde5riVjSyypB4M0VX8D',
    title: 'Học 1 được 4',
    description:
      'Học ở DOL không chỉ giúp các bạn đạt được điểm IELTS cao trong thời gian ngắn mà còn ứng dụng dễ dàng trong môi trường học tập trên đại học và giao tiếp trong công việc và tăng cả critical thinking',
    action: {
      type: 'VIDEO',
      link: {
        title: 'Xem video học viên chia sẻ',
        modalTitle: 'Video học viên chia sẻ',
        url: 'https://www.youtube.com/embed/OhMesTzyi2I'
      }
    }
  },
  {
    image: 'AnhNKGTsSwDpeFJpnYFA',
    title: 'Giáo viên giỏi và có tâm',
    description:
      'Có trình độ chuyên môn cao, nhiệt huyết, tận tâm. Là Thủ khoa/Á khoa, Top 1% SAT, Giảng viên tại các trường Đại học có tiếng tại TP.HCM.',
    action: {
      type: 'LINK',
      link: {
        title: 'Gặp gỡ GV của bạn',
        url: 'https://www.dolenglish.vn/linearteachers'
      }
    }
  },
  {
    image: 'PrXWulOuSm26o4QdVqVB',
    title: 'Đảm bảo đầu ra',
    description:
      'Mọi khoá học ở DOL đều đảm bảo đầu ra. Các bạn học viên được học lại miễn phí nếu không đạt kết quả. Tuy nhiêm tỷ lệ này rất thấp, chỉ 5%, chủ yếu do tâm lý phòng thi.',
    action: {
      type: 'LINK',
      link: {
        title: 'Xem chính sách của DOL',
        url: 'https://www.dolenglish.vn/dam-bao-dau-ra'
      }
    }
  },
  {
    image: 'HyO3bvvYRqGMqEcExLzU',
    title: 'Luôn đặt học viên lên hàng đầu',
    description:
      'DOL luôn theo tiêu chí student-centric (tập trung vào học viên). Mỗi học viên sẽ được giáo viên cung cấp lộ trình học và theo sát quá trình tiến.',
    action: {
      type: 'LINK',
      link: {
        title: 'Xem Review',
        url: 'https://www.dolenglish.vn/hall-of-fame'
      }
    }
  },
  {
    image: '5GjdTJG8THS0kZysJLhr',
    title: 'Nền tảng công nghệ độc quyền',
    description:
      'Từ việc làm Assignment và nhận bài sửa, đến việc luyện tập Reading, Listening Test và học Từ Vựng đã được DOL tích hợp trong hệ thống luyện tập về nhà, dành riêng cho học viên của DOL.',
    action: {
      type: 'VIDEO',
      link: {
        title: 'Xem video',
        modalTitle: 'Xem video app sửa bài ở DOL',
        url: 'https://asset.dolenglish.vn/U0u1nwe2TpaEfcypgc9k'
      }
    }
  },
  {
    image: '7kmmRCQhSTG49wCX3NuR',
    title: 'Cơ sở vật chất hiện đại',
    description:
      'DOL đầu tư vào cơ sở vật chất “xịn xò", hiện đại nhất từ phòng học thông thoáng và sạch sẽ, phòng tự học yên tĩnh, sảnh chờ, để tạo môi trường học thoải mái và tiện nghi nhất cho học viên.',
    action: {
      type: 'VIDEO',
      link: {
        title: 'Xem video',
        modalTitle: 'Video nền tảng công nghệ',
        url: 'https://www.youtube.com/embed/G6TvFZDua48'
      }
    }
  }
];

const IELTSCourseList = withMarketingSection(
  CourseList,
  {
    style: {
      padding: 0,
      position: 'relative',
      zIndex: 2
    },
    selfish: true
  },
  { maxWidth: 1080 }
);
const WhyLearnAtDOLList = withMarketingSection(
  WhyLearnAtDOLSection,
  {
    style: {
      padding: 0,
      position: 'relative',
      zIndex: 2
    },
    selfish: true
  },
  { maxWidth: 1080 }
);

const TitleLayout = styled.div`
  padding-bottom: 24px;
  ${fromScreen(1366)} {
    padding-bottom: 8px;
  }
`;

const StudentCoursePage = () => {
  const { courseWithTeachers } = useCoursesWithTeacher();
  return (
    <MainLayout
      renderCustomHeader={() => <HeaderLandingLuyenThi />}
      noHeader
      hideSideCTA
      noFooter
    >
      <MySEO
        title="Luyện thi IELTS Tư Duy - Cấp Tốc - Đảm bảo đầu ra | DOL English - Anh Ngữ Đình Lực"
        description="Các khóa Luyện thi IELTS Tư Duy - Cấp Tốc - Đảm bảo đầu ra tại DOL English - Anh Ngữ Đình Lực với Linearthinking độc quyền, đội ngũ giáo viên tận tâm và nền tảng công nghệ tiên tiến giúp học viên tăng điểm IELTS siêu tốc, học được - xài được. Không chỉ đạt được điểm số IELTS mong muốn mà còn cải thiện khả năng sử dụng tiếng Anh trong môi trường giao tiếp, học tập và làm việc."
      />
      <FixedTopBarScholarshipScheduleNOffline />

      <LandingSectionLayout>
        <LandingLuyenThiBannerLayout
          content={
            <BannerLayout>
              <BannerLuyenThi data={bannerData} />
              <BenefitList data={benefitList} />
            </BannerLayout>
          }
        />

        <IELTSCourseList
          courses={courseWithTeachers}
          desktopGroupCourses={desktopGroupCourses}
          mobileGroupCourses={mobileGroupCourses}
          title={
            <TitleLayout>
              <LandingSectionTitle>
                🚀 Các khoá IELTS tại DOL
              </LandingSectionTitle>
            </TitleLayout>
          }
        />

        <WhyLearnAtDOLList
          title="😎 6 lý do phải học IELTS tại DOL"
          reasons={reasonsLearnAtDOL}
        />
      </LandingSectionLayout>
    </MainLayout>
  );
};

export default StudentCoursePage;
